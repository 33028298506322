import { ThemeOptions } from '@mui/material';
import { tranlate } from '../LocalizationJson/i18';
import { ROUTES } from '../helpers/contants';
import Images from './images';

const t = (k: string) => tranlate(k);

export const THEME_OPTIONS: ThemeOptions = {
  palette: {
    primary: {
      light: '#223a48',
      main: '#5089AC',
      dark: '#39617A',
      contrastText: '#fff',
    },
    secondary: {
      light: '#111927',
      main: '#EEF3F7',
      dark: '#e6f4ff',
      contrastText: '#5089AC',
    },
    error: {
      light: '#FEE4E2',
      main: '#F04438',
      dark: '#F04438',
      contrastText: '#F04438',
    },
    info: {
      light: '#FEE4E2',
      main: '#F04438',
      dark: '#e6f4ff',
      contrastText: '#F04438',
    },
    grey: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D2D6DB',
      400: '#9DA4AE',
      500: '#6C737F',
      600: '#4D5761',
      700: '#384250',
      800: '#1F2A37',
      900: '#111927',
      A100: 'red',
      A200: 'blue',
      A400: 'green',
      A700: 'yellow',
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    fontWeightRegular: 400,
  },
};

export const s3Url =
  process.env.mode && process.env.mode === 'preprod'
    ? process.env.IMG_SERVE_URL
    : process.env.S3_URL || '';

export const DefaultProfileImg = `${s3Url}ReelProject/1701757389017user%20%281%29.png`;
export const mobileNumberRegex = /^\+([1-9]{1}[0-9]{1,3})-([0-9]{7,15})$/;
export const PriceRegex =
  /^(?=[0-9]*(\.[0-9]{0,2})?$)(?!-)[0-9]{0,9}(\.[0-9]{0,2})?$/;
export const CHATS = 'Chats';
export const ZeroRegex = /^0$/;
export interface Navigation {
  name: string;
  path: Array<string>;
  icon: any;
  icon_active: any;
  variantPath?: any;
}

export const navigation: Navigation[] = [
  // {
  //   name: 'Dashboard',
  //   path: [ROUTES.DASHBOARD],
  //   icon: Images.DASHBOARD,
  //   icon_active: Images.DASHBOARD_ACTIVE,
  // },
  {
    name: 'Order Management',
    path: [
      ROUTES.ORDERS,
      ROUTES.ORDERS_DETAILS,
      ROUTES.ORDERS_WORKSHEET,
      ROUTES.ORDERS_PRODUCTS,
      ROUTES.ADD_PRODUCTS_ORDERS,
      ROUTES.DRAFT_DETAILS,
    ],
    icon: Images.ORDERS,
    icon_active: Images.ORDERS_ACTIVE,
  },
  {
    name: 'Product Management',
    path: [
      ROUTES.PRODUCTS,
      ROUTES.ADD_PRODUCTS,
      ROUTES.ADD_SERVICES,
      ROUTES.EDIT_SERVICE,
      ROUTES.EDIT_PRODUCTS,
      ROUTES.ADD_PACKAGE,
      ROUTES.EDIT_PACKAGE,
      ROUTES.ADD_VARIANT,
      ROUTES.EDIT_VARIANT,
      ROUTES.ADD_SERVICE_VARIANT,
      ROUTES.EDIT_SERVICE_VARIANT,
      ROUTES.GROUPS,
      ROUTES.ADD_GROUP,
      ROUTES.EDIT_GROUP,
    ],
    variantPath: [
      ROUTES.ADD_SERVICE_VARIANT,
      ROUTES.EDIT_SERVICE_VARIANT,
      ROUTES.ADD_VARIANT,
      ROUTES.EDIT_VARIANT,
    ],
    icon: Images.PRODUCTS,
    icon_active: Images.PRODUCTS_ACTIVE,
  },
  {
    name: 'Branch Management',
    path: [
      ROUTES.BRANCHES,
      ROUTES.ADD_BRANCH,
      ROUTES.EDIT_BRANCH,
      ROUTES.VIEW_BRANCH,
    ],
    icon: Images.BRANCH,
    icon_active: Images.BRANCH_ACTIVE,
  },
  {
    name: 'Financials',
    path: [ROUTES.FINANCIALS],
    icon: Images.FINANCIALS,
    icon_active: Images.FINANCIALS_ACTIVE,
  },
  {
    name: 'Chats',
    path: [ROUTES.CHATS, ROUTES.ORDER_SERVICES],
    icon: Images.CHATS,
    icon_active: Images.CHATS_ACTIVE,
  },
  {
    name: 'Company Management',
    path: [ROUTES.COMPANY],
    icon: Images.COMPANY,
    icon_active: Images.COMPANY_ACTIVE,
  },
  {
    name: 'Client Management',
    path: [ROUTES.CLIENTS, ROUTES.CLIENTDETAILS, ROUTES.CLIENTSETDETAILS],
    icon: Images.CLIENTS,
    icon_active: Images.CLIENTS_ACTIVE,
  },
  {
    name: t('Employ_Management.pageName'),
    path: [
      ROUTES.EMPLOY,
      ROUTES.ADD_EMPLOY,
      ROUTES.EDIT_EMPLOY,
      ROUTES.VIEW_EMPLOY,
    ],
    icon: Images.CO_VENDOR,
    icon_active: Images.CO_VENDOR_ACTIVE,
  },
  {
    name: 'Driver Management',
    path: [
      ROUTES.DRIVERS,
      ROUTES.DRIVER_ADD,
      ROUTES.DRIVER_VIEW,
      ROUTES.VEHICLE_ADD,
      ROUTES.VEHICLE_VIEW,
    ],
    icon: Images.DRIVER,
    icon_active: Images.DRIVER_ACTIVE,
  },
  {
    name: 'Risk Management',
    path: [
      ROUTES.RISK_MANAGEMENT,
      ROUTES.ADD_RISK_USER,
      ROUTES.VIEW_RISK_USER,
      ROUTES.EDIT_RISK_USER,
    ],
    icon: Images.RISK_MNG,
    icon_active: Images.RISK_MNG_ACTIVE,
  },
  // {
  //   name: 'Group Management',
  //   path: [
  //     ROUTES.GROUPS,
  //     ROUTES.ADD_GROUP
  //   ],
  //   icon: Images.RISK_MNG,
  //   icon_active: Images.RISK_MNG_ACTIVE,
  // },
];

export const ManagerNav = [
  'Dashboard',
  'Order Management',
  'Product Management',
  'Financials',
  'Chats',
  'Products',
  'Services',
  'Orders',
  'Chats',
  'Profile',
  'Groups Management',
];

export const ManagerRisk = [
  'Risk Management',
  // 'Product Management'
];

export const businessType = [
  'State-Owned Business',
  'Publicly-Listed Business',
  'Privately-Owned Business',
  'Charity',
  'Other',
];

export enum PRODUCT_TYPES {
  purchase = 'Purchase',
  rental = 'Rental',
  rentAndPurchase = 'Purchase & Rent',
}

export const ITEM_TYPE = {
  SERVICE: 'SERVICE',
  PRODUCT: 'PRODUCT',
  PACKAGE: 'PACKAGE',
};

export const typeOptions = [
  { value: 'SAFETY', label: 'Safety Advisor' },
  { value: 'SECURITY', label: 'Security Advisor' },
  { value: 'EXECUTIVE', label: 'Executive Protection' },
];

export const tierOptions = [
  { value: 'TIER1', label: 'Tier 1 - Entry Level' },
  { value: 'TIER2', label: 'Tier 2 - Mid Level' },
  { value: 'TIER3', label: 'Tier 3 - Senior Level' },
];

export enum BACKEND_P_TYPES {
  PURCHASE = 'PURCHASE',
  RENTAL = 'RENTAL',
  SERVICE = 'SERVICES',
  SERVIC = 'SERVICE', ///Used for package
  RENTAL_AND_PURCHASE = 'RENTAL_AND_PURCHASE',
}

export const SHOPPING_MODE = {
  RENT: 'RENT',
  SERVICE: 'SERVICE',
};
export const PRODUCT_TYPE_BOTH = {
  allProducts: `${BACKEND_P_TYPES.PURCHASE},${BACKEND_P_TYPES.RENTAL_AND_PURCHASE}`,
};
export const PACKAGE_TYPES = [
  {
    label: t('Product_Management.productName'),
    value: 'PRODUCTS',
  },
  { label: t('Product_Management.serviceName'), value: 'SERVICES' },
  {
    label: t('Product_Management.productAndService'),
    value: 'PRODUCTS_AND_SERVICES',
  },
];

export const PACKAGE_TYPES_KEYS: any = {
  PRODUCTS: t('Product_Management.productName'),
  SERVICES: t('Product_Management.serviceName'),
  PRODUCTS_AND_SERVICES: t('Product_Management.productAndService'),
};

export enum USER_ROLES {
  BRANCH_MANAGER = 'BRANCH_MANAGER',
  VENDOR = 'VENDOR',
  CO_VENDOR = 'CO_VENDOR',
}

export const RATE_MODEL_OPTIONS = ['Hourly', 'Daily', 'Weekly'];

export const RATE_MODELS = [
  {
    label: 'Hourly',
    value: 'HOURLY',
  },
  {
    label: 'Day',
    value: 'DAILY',
  },
  {
    label: 'Week',
    value: 'WEEKLY',
  },
  {
    label: 'Month',
    value: 'MONTHLY',
  },
];

export const RATE_MODELS_BACKEND: any = {
  HOURLY: 'Hourly',
  DAILY: { s: 'Day', p: 'Days' },
  WEEKLY: { s: 'Week', p: 'Weeks' },
  MONTHLY: { s: 'Month', p: 'Months' },
};

export const BILLABLE_MINIMUMS = ['4', '8', '12', 'Others'];

export const DOC_TYPE = [
  'Business Incorporation Paper',
  'Business License ID',
  'Driving License',
  'Passport',
];

export enum FORM_STEPS {
  BILLING_DETAILS = 'BILLING_DETAILS',
  BUSINESS_INFO = 'BUSINESS_INFO',
  LEGAL_DOC = 'LEGAL_DOC',
  CONTACT_INFO = 'CONTACT_INFO',
}

export const PRODUCT_TABS = {
  product: {
    label: t('Product_Management.productName'),
    value: 'PRODUCT',
  },
  service: {
    label: t('Product_Management.serviceName'),
    value: 'SERVICE',
  },
  package: {
    label: t('Product_Management.packageName'),
    value: 'PACKAGE',
  },
};

export const RISK_MANAGEMENT_TABS = {
  order: {
    label: 'Order Requests',
    value: 'ORDERS',
  },
  account: {
    label: 'Account Requests',
    value: 'ACCOUNT_REQUESTS',
  },
  users: {
    label: 'Users',
    value: 'USERS',
  },
};

export const max20CharsRegex = /^.{0,20}$/;

export enum STATIC_CONTENT {
  'FAQ',
  'CONTACT_US',
  'PRIVACY_POLICY',
  'TERMS_AND_CONDITIONS',
}

export const StaticRoutes = [
  ROUTES.POLICIES,
  ROUTES.FAQ,
  ROUTES.TERMS,
  ROUTES.SUPPORT,
];

export const DEFAULT_MANAGER_OPTION: any = [
  {
    firstName: '',
    lastName: '',
    email: '',
  },
];

export enum PAGE_MODES {
  ADD = 'ADD',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  LIST = 'LIST',
}

export enum ENTITY_LISTS {
  EXPERIENCE = 'EXPERIENCE',
  EXPERIENCE_SAFETY = 'EXPERIENCE_SAFETY',
  AREA_OF_WORK = 'AREA_OF_WORK',
  QUALIFICATION = 'QUALIFICATION',
  SAFETY_EXPERIENCE = 'SAFETY_EXPERIENCE',
  INTERNATIONAL = 'INTERNATIONAL',
  PRODUCTION_EXPERIENCE = 'PRODUCTION_EXPERIENCE',
  UNITED_STATES = 'UNITED_STATES',
  SPECIALTY_TRAINING = 'SPECIALTY_TRAINING',
  EDUCATION_EXPERIENCE = 'EDUCATION_EXPERIENCE',
  SPECIALTY_EXPERIENCE = 'SPECIALTY_EXPERIENCE',
  SKILL_AND_TRAINING = 'SKILL_AND_TRAINING',
  SKILL_AND_TRAINING_SAFETY = 'SKILL_AND_TRAINING_SAFETY',
  PROFESSIONAL_BACKGROUND = 'PROFESSIONAL_BACKGROUND',
  REGION = 'REGION',
  DEGREE_TYPE = 'DEGREE_TYPE',
  CERTIFICATE_TYPE = 'CERTIFICATE_TYPE',
  ADDITIONAL_REGION = 'ADDITIONAL_REGION',
}

export enum PERMISSION_TYPES {
  EDIT = '2',
  VIEW = '1',
}

export const DISCOUNT_TYPES: { [key: string]: string } = {
  '%': 'PERCENTAGE',
  Flat: 'FLAT',
};

export const DISCOUNT_TYPES_BACKEND: { [key: string]: string } = {
  PERCENTAGE: '%',
  FLAT: 'Flat',
};

export const DELIVERY_TYPES_BACKEND: { [key: string]: string } = {
  DROP_OFFS: 'Drop-offs',
  SELF_PICKUP: 'Self pickup',
};

export const DELIVERY_TYPES: { [key: string]: string } = {
  'Drop-offs': 'DROP_OFFS',
  'Self pickup': 'SELF_PICKUP',
};

export const MINIMUM_RATE_MODEL = [
  {
    label: t('Constant_Data.rateModel.day'),
    value: 'DAILY',
  },
  { label: t('Constant_Data.rateModel.week'), value: 'WEEKLY' },
  { label: t('Constant_Data.rateModel.month'), value: 'MONTHLY' },
];

export const EXTEND_PRICE_MODELS = {
  BASE_RATE: 'BASE_RATE',
  MINIMUM_RATE: 'MINIMUM_RATE',
};

export const PRICE_MODE_TYPES = {
  PER_HOUR: 'PER_HOUR',
  PER_DAY_WEEK_MONTH: 'PER_DAY_WEEK_MONTH',
};

export const DEFAULT_MODEL_OPTION: any = [
  {
    priceMode: PRICE_MODE_TYPES.PER_HOUR,
    rentPrice: null,
    minRateModel: MINIMUM_RATE_MODEL[0].value,
    DTStartTime: null,
    OTStartTime: null,
    minDuration: null,
  },
];

export const DefaultProductPackage = {
  product: null,
  qty: 1,
};

export enum STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  REJECTED ='REJECTED',
  AVAILABLE ='AVAILABLE',
  UN_AVAILABLE="UN_AVAILABLE"
}

export const ORDER_STATUS = [
  {
    label: t('orders.status.completed'),
    value: 'COMPLETED',
  },
  { label: t('orders.status.pending'), value: 'PENDING' },
  { label: t('orders.status.canceled'), value: 'CANCELLED' },
];

export const OrderStatusLabel: any = {
  CANCELLED: t('orders.status.canceled'),
  PENDING: t('orders.status.pending'),
  COMPLETED: t('orders.status.completed'),
  INITIATED: 'Initiated',
};

export enum ORDER_PRODUCT_TYPES {
  rent = 'RENT',
  buy = 'BUY',
  service = 'SERVICE',
}

export const ORDER_PRODUCT_STATUS: any = {
  PLACED: 'Order Placed',
  PENDING: 'PENDING',
  ACCEPTED: 'Accepted',
  REJECTED: 'Rejected',
  COMPLETED: 'Completed',
  CANCELLED: 'Canceled',
  DELIVERED: 'Delivered',
  INPROGRESS: 'INPROGRESS',
  RETURNED: 'RETURNED',
  SHIPPED: 'Shipped',
  INTRANSIT: 'In Transit',
  ENDED: 'Service Ended',
  STARTED: 'Service Started',
};

export const ADDRESS_TYPES = {
  BUSINESS: 'BUSINESS',
  PERSONAL: 'PERSONAL',
};
export const DELIVERY_MODES: any = {
  'Hand it to me': 'inHand',
  'Leave it at Location': 'leftAtDoor',
  // 'Leave it at Lcation': 'leaveItAtLocation',
};
export const PRODUCTION_TYPE = {
  FEATURE_FILM: 'Feature Film',
  TV_SERIES: 'TV Series',
  COMMERCIAL: 'Commercial',
  DOCUMENTARY: 'Documentary',
  MUSIC_VIDEO: 'Music Video',
  EVENT: 'Event',
  OTHER: 'Other',
};

export const SHIFT_COVERAGE: any = {
  Day: 'DAY',
  Night: 'NIGHT',
  '24 Hrs': '24H',
  'Swing Shift': 'SWING_SHIFT',
};

// 100 MB
export const MAX_FILE_SIZE_CHAT = 100 * 1024 * 1024;
// 10 files
export const MAX_FILES = 10;

export const numberInputRestrictKeys = [
  'Tab',
  'Escape',
  'Enter',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'e',
  '+',
  '-',
];

export enum NOTIFICATION_TYPES {
  'CHAT' = 'CHAT',
  'ORDER' = 'ORDER',
  'VENDOR_DOC_EXPIRED' = 'VENDOR_DOC_EXPIRED',
  'VENDOR_UPDATE_COMPANY' = 'VENDOR_UPDATE_COMPANY',
  'VENDOR_CHANGEPASS' = 'VENDOR_CHANGEPASS',
  'VENDOR_ONBOARDING' = 'VENDOR_ONBOARDING',
}

const priceFields = [];

export const discountTypeOptions = [
  { label: 'Purchase', value: 'PURCHASE' },
  { label: 'Rental', value: 'RENTAL' },
  { label: 'Service', value: 'SERVICE' },
  // { label: 'Package', value: 'PACKAGE' },
];
export const onlyApphabetsRejex = /^[A-Za-z ]*$/;

export const nameRegex = /^[A-Za-z‘']*$/;
export const userNameRegex = /^[A-Za-z‘' ]*$/;

export const countryRegex = /^(1000|[1-9][0-9]{3})$/;

export const URL_REGEX =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9\-]+(\.[a-zA-Z0-9\-]+)+)(\/[a-zA-Z0-9\-\._~:\/?#[\]@!$&'()*+,;=]*)?$/;

export enum PRODUCT_TYPES_LISTING {
  'PRODUCT' = 'PRODUCT',
  'SERVICE' = 'SERVICE',
}

export enum HOLIDAY_TYPES {
  CUSTOM_HOLIDAY = 'CUSTOM_HOLIDAY',
  PUBLIC_HOLIDAY = 'PUBLIC_HOLIDAY',
}
